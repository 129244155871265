<template>
    <div class="system-roles list-page page">

        <div class="page--list" style="background:#fff;">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <list-tool-bar>
                    <el-button type="primary" style="margin-bottom:15px;" v-if="auth.update" @click.stop="handleCheckUpdate">重新加载当月考核员工</el-button>
                    <el-button type="primary"  style="margin-bottom:15px;" v-if="auth.batchGrant" @click.stop="handleAllGrantDia">批量发放绩效工资</el-button>
                    <el-button  style="margin-bottom:15px;" v-if="auth.export" @click.stop="handleExportBtn"><i class="el-icon-devops" />导出绩效</el-button>
                </list-tool-bar>
                <!-- regionList postAllList searchValue  searchValueType 4219621 4230976 -->
                <el-row style="flex-wrap: wrap;padding:16px 16px 0 16px;background:#F4F7FA;" class="flex">
                    <div class="flex a-center" style="margin-right:32px;margin-bottom:16px;">
                        <el-input  placeholder="请输入内容" v-model.trim="searchValue" class="input-with-select width2">
                            <el-select style="width:120px;" v-model="searchValueType" slot="prepend" placeholder="请选择">
                                <el-option v-for="item in searchTypeEnum" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </el-input>
                    </div>

                    <div class="flex a-center" style="margin-right:32px;margin-bottom:16px;">
                        <div>区域：</div>
                        <el-select class="width2" v-model.trim="searchRegion" clearable slot="prepend" placeholder="请选择">
                            <el-option v-for="item in regionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom:16px;margin-right:32px;" class="flex a-center">
                        <div>绩效月份：</div>
                        <el-date-picker  :type="datetype" class="width2" v-model="searchTime" :value-format="valueformat" placeholder="请选择"></el-date-picker>
                    </div>

                    <div class="flex a-center" style="margin-right:32px;margin-bottom:16px;">
                        <div>岗位：</div>
                        <el-select class="width2" v-model="searchPost" slot="prepend" placeholder="请选择">
                            <el-option v-for="item in postAllList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <div class="flex a-center" style="margin-right:32px;margin-bottom:16px;">
                        <div>是否核算绩效工资：</div>
                        <el-select class="width1" v-model="searchIsCheck" slot="prepend" placeholder="请选择">
                            <el-option v-for="item in searchCheckArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </div>

                   
                    <div style="margin-bottom:16px;">
                        <el-button style="" type="primary" @click.stop="handleSubmit">搜索</el-button>
                        <el-button @click.stop="handleClear">清空</el-button>
                        
                    </div>
                </el-row>

                <el-table :data="tableData">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column label="区域/部门" prop="orgName" ></el-table-column>
                    <el-table-column label="员工编号" prop="usercode"></el-table-column>
                    <el-table-column label="员工姓名" prop="empName"></el-table-column>
                    <el-table-column label="员工岗位" prop="postName"></el-table-column>
                    <el-table-column label="绩效月份" prop="meritsTimeName"></el-table-column>
                    <el-table-column label="移民绩效单数" prop="aymSingleNum">
                         <template slot-scope="scope">
                            <div class="t-center">
                                {{scope.row.aymSingleNum}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="债券绩效单数" prop="monSingleNum">
                        <template slot-scope="scope">
                            <div class="t-center">
                                {{scope.row.monSingleNum}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="个人绩效单数" prop="singleNum">
                         <template slot-scope="scope">
                            <div class="t-center">
                                {{scope.row.singleNum}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否核算绩效工资" prop="isCheckName">
                         <template slot-scope="scope">
                            <div class="cell-list-item">
                                <div  :class="scope.row.isCheck == 1 ? 'fine':'fail'">{{scope.row.isCheckName}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="核算绩效工资" prop="checkMoney">
                         <template slot-scope="scope">
                            <div class="t-right">
                                {{scope.row.checkMoney}}
                            </div>
                        </template>

                    </el-table-column>
                    <el-table-column label="实际发放绩效月份" prop="grantTimeName"></el-table-column>
                    <el-table-column label="实际发放绩效工资" prop="grantMoney">
                        <template slot-scope="scope">
                            <div class="t-right">
                                {{scope.row.grantMoney}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150px" fixed="right">
                        <template slot-scope="scope">
                            <action-list :firstItemNum='1' :list="scope.row.actionList"
                                :offset="-30"
                                @action="handleAction($event, scope)"></action-list>
                        </template>
                    </el-table-column>


                    <!-- <el-table-column label="操作"
                        fixed="right">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="scope.row.postcanshow && auth.verify"
                                    type="text"
                                    @click.stop="openVerifyDia(scope.row)"
                                >核算绩效工资</el-button>
                                <el-button
                                    v-if="scope.row.postcanshow&&auth.grant"
                                    type="text"
                                    @click.stop="openGrantDia(scope.row)"
                                > 发放绩效工资</el-button>
                                <el-button
                                    v-if="auth.detail"
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                > 绩效详情</el-button>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>

        <!-- 核算绩效工资 -->
        <el-dialog class="verifyDia"
        :visible="showVerifyDia"
        @close="cancelCheckAchievement"
        title="核算绩效工资"
        width="800px">
           
            <div style="margin-bottom:8px;display:flex;"> 

                <el-form 
                    :model="PB"
                    :rules="rules"
                    label-width="130px"
                    label-suffix=":"
                    ref="form"
                    style="width:100%;"
                    @submit.prevent="handleSave"
                >
                    <div class="flex">
                        <el-form-item label="员工编号" class="w_50">
                            <div >
                                {{empInfo.usercode}}   
                            </div>
                        </el-form-item>
                        <el-form-item label="员工姓名" class="w_50">
                            <div >
                                {{empInfo.empName}}   
                            </div>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="绩效月份" class="w_50">
                            <div >
                                {{empInfo.meritsTimeName}}   
                            </div>
                        </el-form-item>
                        <el-form-item label="岗位" class="w_50">
                            <div >
                                {{empInfo.postName}}   
                            </div>
                        </el-form-item>
                    </div>
                    <el-form-item label="个人绩效单数" >
                        <div >
                                {{clickrow.singleNum}}   
                        </div>
                    </el-form-item>
                     <el-form-item label="封顶绩效工资" style="margin-bottom:24px;">
                         <el-input style="width:380px;" placeholder="" v-model.trim="salaryInfo.cappingMerits" disabled readonly=""></el-input>
                    </el-form-item>
                    <el-form-item label="核算绩效工资" prop="checkMoney" style="margin-bottom:24px;">
                        <el-input  v-model.trim="PB.checkMoney" style="width:380px;"
                         maxlength="20"></el-input>
                    </el-form-item>
                     <el-form-item label="备注" prop="remark">
                        <el-input v-model.trim="PB.remark"
                        style="width:554px;"
                            type="textarea" rows="5"
                         maxlength="100"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="关联合同模板" prop="tempid">
                        <el-select v-model.trim="PB.tempid"
                            class="width3">
                            <el-option
                                v-for="e in contractTempList"
                                :key="e.id"
                                :label="e.title"
                                :value="e.tempCode"
                            ></el-option>
                        </el-select>
                    </el-form-item> -->
                </el-form>
            </div>
            <div slot="footer">
                <el-button @click="cancelCheckAchievement" >取消</el-button>
                <el-button @click="handleSave()"  type="primary">确认核算</el-button>
                <el-button @click="handleSave('next')"  type="primary" v-if="auth.grant">核算并发放绩效工资</el-button>
            </div>
        </el-dialog>
         <!-- 发放绩效工资-->
        <el-dialog class="grantDia"
        :visible="showGrantDia"
        @close="cancelGrantDia()"
        title="发放绩效工资"
        width="800px">
            <div style="margin-bottom:8px;display:flex;"> 
                <el-form 
                    :model="grant"
                    :rules="grantrules"
                    label-width="140px"
                    label-suffix=":"
                    ref="grantform"
                    style="width:100%;"
                    @submit.prevent="handleGrant"
                >
                    <div class="flex">
                        <el-form-item label="员工编号" class="w_50">
                            <div >
                                {{empInfo.usercode}}   
                            </div>
                        </el-form-item>
                        <el-form-item label="员工姓名" class="w_50">
                            <div >
                                {{empInfo.empName}}   
                            </div>
                        </el-form-item>
                    </div>
                    <div class="flex">
                        <el-form-item label="岗位" class="w_50">
                            <div >
                                {{empInfo.postName}}   
                            </div>
                        </el-form-item>
                        <el-form-item label="绩效月份" class="w_50">
                            <div >
                                {{empInfo.meritsTimeName}}   
                            </div>
                        </el-form-item>
                        
                    </div>
                     <el-form-item label="核算绩效工资" style="margin-bottom:24px;">
                         <el-input style="width:380px;" v-model.trim="empInfo.checkMoney" disabled readonly></el-input>
                    </el-form-item>


                    <el-form-item label="绩效工资发放月份" prop="grantTime" style="margin-bottom:24px;">
                         <el-date-picker  :type="datetype"  style="width:380px;" v-model.trim="grant.grantTime" :value-format="valueformat" placeholder="请选择"></el-date-picker>
                    </el-form-item>

                    <el-form-item label="实发绩效工资"  prop="grantMoney" style="margin-bottom:24px;">
                         <el-input style="width:380px;" v-model.trim="grant.grantMoney" ></el-input>
                    </el-form-item>
                   
                     <el-form-item label="备注" >
                        <el-input v-model.trim="grant.remark"
                        style="width:554px;"
                            type="textarea" rows="5"
                         maxlength="100"></el-input>
                    </el-form-item>
                </el-form>
              
            </div>
            

            <div slot="footer">
                <el-button @click="cancelGrantDia" >取消</el-button>
                <el-button @click="handleGrantSave"  type="primary">保存</el-button>
                
            </div>
        </el-dialog>

        <!-- 批量发放绩效工资 -->
        <el-dialog class="allGrantDia"
        :visible="showAllGrantDia"
        @close="cancelAllGrantDiaTop"
        title="批量发放工资"
        width="560px">
         <el-form 
                :model="allGrant"
                :rules="allGrantrules"
                label-width="140px"
                label-suffix=":"
                ref="allGrantform"
                style="width:100%;"
                @submit.prevent="submitUpload"
                >
            <el-form-item label="上传附件" prop="fileList" >
               <el-upload
                :data='allGrantData'
                :on-exceed="handleExceed"
                :limit='1'
                class="upload-demo"
                :headers='uploadHeaders'
                ref="allGrantformUpload"
                :action="$baseURL+'/api/empMerits/batchGrantPerformanceMoney'"
                :file-list="allGrant.fileList"
                :on-success="handleUploadSuccess"
                :on-change="handleFileChange"
                :on-remove="handleRemove"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>

                 <el-button slot="trigger"  type="text" style="margin-left: 10px;" 
                        @click.stop="downLoadfile"
                    >下载模板</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="绩效工资核算月份" prop="calMonth" style="margin-bottom:24px;">
                    <el-date-picker  :type="datetype"  style="width:300px;" v-model.trim="allGrant.calMonth" :value-format="valueformat" placeholder="请选择"></el-date-picker>
            </el-form-item>
            <el-form-item label="绩效工资发放月份" prop="grantMonth" style="margin-bottom:24px;">
                    <el-date-picker  :type="datetype"  style="width:300px;" v-model.trim="allGrant.grantMonth" :value-format="valueformat" placeholder="请选择"></el-date-picker>
            </el-form-item>
         </el-form>
            <div slot="footer">
                <el-button @click="cancelAllGrantDia" >取消</el-button>
                <el-button @click="submitUpload"  type="primary">保存</el-button>
            </div>
        </el-dialog>

        
   
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            tableData: [],
            regionList:[],
            postAllList:[],
            searchRegion:'',
            searchTime:'',
            searchPost:'',
            searchIsCheck:'',
            searchCheckArr: [
                { name: "未核算", id: 0 },
                { name: "已核算", id: 1 },
            ],
            valueformat:'yyyy-MM',
            datetype:'month',
            showVerifyDia: false,//核算绩效工资
            showGrantDia: false,//发放绩效工资

            PB: {
                checkMoney: "",
                remark:'',
            },
            rules: {
                // checkMoney      : { required: true, message: "请输入核算绩效工资",trigger:['change', 'blur'] },
                 checkMoney: [
                    {required: true, message: "请输入核算绩效工资"},
                    { validator (r, val, c) {
                        if (typeof val === "number") c()
                        else if (!/^\d{1,11}(\.\d{1,2})?$/.test(val)) {
                            c("请输入数字, 最多包含两位小数")
                        }
                        else c()
                    }}
                ]
            },
             grant: {
                grantTime:'',
                grantMoney: "",
                remark:'',
            },
            grantrules: {
                grantTime      : { required: true, message: "请选择绩效工资发放月份",trigger:['change', 'blur'] },
                // grantMoney      : { required: true, message: "请输入实发绩效工资",trigger:['change', 'blur'] },
                grantMoney: [
                    {required: true, message: "请输入实发绩效工资"},
                    { validator (r, val, c) {
                        if (typeof val === "number") c()
                        else if (!/^\d{1,11}(\.\d{1,2})?$/.test(val)) {
                            c("请输入数字, 最多包含两位小数")
                        }
                        else c()
                    }}
                ]
            },
            
            
            clickrow:'',
            auth:{
               update:false,
               export:false,
               verify:false,
               grant:false,
               detail:false,
               batchGrant:false,
            },
            salaryInfo:{},
            empInfo:{},
            todayStr:'',


            actionList : [
                {
                    label: "核算绩效工资",
                    name: "verify",
                    banned: false
                },
                {
                    label: "发放绩效工资",
                    name: "grant",
                    banned: false
                },
                {
                    label: "绩效详情",
                    name: "detail",
                    banned: false
                },
            ],




            searchValue: "",
            searchValueType: 1,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            searchStartTime: "",
            searchEndTime: "",
            

            searchTypeEnum: [
                { name: "员工姓名", value: 1 },
                { name: "员工编号", value: 2 },
            ],

            multipleSelection: "",
            sysCode: "",
            
            
            uploadHeaders: { // 图片上传的请求头
                token: this.$store.state.token
            },

            showUpdateDia: false,//上传更新数据
            fileList2: [],
            
            uploadObjs:{
                sysCode:'',
                isConfirm:0,
            },
            updatedia1:false,
            updianum1:0,
            updianum2:0,
            updianum3:0,
            updianum4:0,
            upBackfilePathName:'',


            allGrant:{
                 calMonth:'',
                 grantMonth:'',
                fileList:[]
            },
            allGrantrules: {
                fileList: {required: true, message: "请上传附件",trigger:"change"},
                calMonth      : { required: true, message: "请选择绩效工资核算月份",trigger:['change', 'blur'] },
                grantMonth      : { required: true, message: "请选择绩效工资发放月份",trigger:['change', 'blur'] }
            },
            showAllGrantDia: false,//
            allGrantData:{},

            
        };
    },
    created() {
        let url = window.location.href;
        this.auth.update = this.$hasAuthFor("api/empMerits/refreshEmpMerits"); //
        this.auth.export = this.$hasAuthFor("api/empMerits/downEmpMerits"); //

        this.auth.verify = this.$hasAuthFor("api/empMerits/saveEmpMeritsCheckInfo/Emp"); //
        this.auth.grant = this.$hasAuthFor("api/empMerits/saveEmpMeritsGrantInfo/Emp"); //
        this.auth.detail = this.$hasAuthFor("api/empMerits/queryEmpMeritsCalculateList/Emp"); //
        this.auth.batchGrant = this.$hasAuthFor("api/empMerits/batchGrantPerformanceMoney"); //


         let today = new Date(), M = today.getMonth();
         this.todayStr = this.getDateStr(today);
         this.grant.grantTime =  this.todayStr
        this.fetchQueryParam();
        this.fetchData();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        handleRemove(file, fileList) {
            this.allGrant.fileList = []
        },
        cancelAllGrantDiaTop(){
            if(this.showAllGrantDia){
                this.cancelAllGrantDia()
            }
        },
        cancelAllGrantDia(){
            this.allGrant.calMonth = ''
            this.allGrant.grantMonth = ''
            this.allGrant.fileList = []
            this.$refs.allGrantformUpload.clearFiles();
            this.showAllGrantDia = false
            setTimeout(() => {
                this.$refs.allGrantform.clearValidate();
            }, 20);
        },
        async downLoadfile() {
            let loadingRef,
                url = '/api/empMerits/downloadGrantPerformanceTemplate'
            loadingRef = this.$loading();

            const res = await this.$axios({
                method: "post", url, data: {}
            })
            console.log(res);
            if (res.code === 2000) {
                // this.$message({
                //     message: "下载成功",
                //     type: "success"
                // });
                if (res.data.path) {
                    window.open(this.$baseURL + '/' + res.data.path, "download");
                }
                loadingRef && loadingRef.close();
            } else if (res.code !== 1003)
                throw res;
        },
        handleAllGrantDia(){
            this.showAllGrantDia = true
        },
        handleExceed() {
            this.$message({
                message: `最多只能上传1个文件`,
                type: "error",
            });
        },
        // 
        handleUploadSuccess(res, file) {
            console.log(res);
            console.log(file);
            this.uploading.close();
            if (res.code == 2001) {
                this.$message({
                    message: res.msg || "文件有误",
                    type: "warning"
                });
                setTimeout(() => {
                    this.$refs.upload.clearFiles();
                    this.allGrant.fileList = []
                }, 20);
            }
            if (res.code == 2000) {
                this.$message({
                    message: '保存成功',
                    type: "success"
                });
                setTimeout(() => {
                    this.cancelAllGrantDia()
                    this.fetchData();
                }, 500);
                setTimeout(() => {
                    if (res.data.path) {
                        window.open(this.$baseURL + '/' + res.data.path, "download");
                    }
                }, 2000);
            }
        },
        handleFileChange(files) {
            this.allGrant.fileList = [files]
        },

        async submitUpload() {
            try {
                // await this.validateUpdate();
                await this.$refs.allGrantform.validate();
                this.allGrantData.calMonth = this.allGrant.calMonth
                this.allGrantData.grantMonth = this.allGrant.grantMonth
                this.uploading =  this.$loading({
                    target: ".allGrantDia .el-dialog"
                });
                    // loadingRef && loadingRef.close();


                this.$refs.allGrantformUpload.submit();
                // this.uploading = this.$loading();
                // this.$refs.upload.submit();

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);

            }

        },
      
        async validateUpdate () {
             if ([...this.allGrant.fileList].some(({percentage,status}) => (percentage < 100 || status =='uploading'))){
                 this.$message({
                     message: "请等待附件上传完毕",
                     type: "error"
                 });
                 return Promise.reject(false)
             }else{
                return true;
             }
        },

        // 点击 表格行的操作按钮
        handleAction (action, scope) {
            this.clickrow = scope.row
            const type = action.name;
            const data = scope.row;
			switch (type) {
				case "verify":
                    this.openVerifyDia(data)
                    break;
				case "grant":
                    this.openGrantDia(data)
					break;
				case "detail":
					this.handleDetail(data)
					break;
				default:
					console.log("有没有操作处理程序");
					break;
			}
        },
        getDateStr (date) {
			if (date instanceof Date) {
				let Y = date.getFullYear();
				let M = date.getMonth() + 1;
				let D = date.getDate();
				M = M < 10 ? `0${M}` : M;
				D = D < 10 ? `0${D}` : D;
				return `${Y}-${M}`;
			}
			else return ""
		},
        // 点击导出
        async handleExportBtn () {
			let loadingRef;
			try {
				await this.$confirm("确定导出吗", "系统提示")
				loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/empMerits/downEmpMerits",
                    method: "post",
					data:{
                        searchRegion:this.searchRegion,
                        searchTime:this.searchTime,
                        searchPost:this.searchPost,
                        searchIsCheck:this.searchIsCheck,
                    }
                })

				if (res.code === 2000) {
					
					window.open(this.$baseURL + "/" + res.data.pathInfo.path)
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },
        openVerifyDia(row){
            if(row.isCheck==1){
                 this.$message({
                    message: "已核算绩效工资",
                    type: "warning"
                });
                return
            }
            this.clickrow = row
            this.showVerifyDia = true
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            this.queryEmpMeritsSalaryInfo()
        },
         async queryEmpMeritsSalaryInfo(data) {
            if(data==2){
                const  loadingRef = this.$loading({
                    target: ".grantDia .el-dialog"
                });
                const res = await this.$axios({
                    url: "/api/empMerits/queryEmpMeritsSalaryInfo",
                    method: "post",
                    data: {
                        id:this.clickrow.id
                    },
                });
                if (res.code === 2000) {
                    this.empInfo= res.data.empInfo
                    this.salaryInfo= res.data.salaryInfo
                    this.PB.checkMoney = this.salaryInfo.realityMerits
                    loadingRef && loadingRef.close();
                } else {
                    this.$message({
                        message: res.msg || "获取员工核算绩效金额和员工信息失败",
                        type: "warning",
                    });
                }
                
            }else{
                const loadingRef = this.$loading({
                    target: ".verifyDia .el-dialog"
                });
                const res = await this.$axios({
                    url: "/api/empMerits/queryEmpMeritsSalaryInfo",
                    method: "post",
                    data: {
                        id:this.clickrow.id
                    },
                });
                if (res.code === 2000) {
                    this.empInfo= res.data.empInfo
                    this.salaryInfo= res.data.salaryInfo
                    this.PB.checkMoney = this.salaryInfo.realityMerits
                    loadingRef && loadingRef.close();
                } else {
                    this.$message({
                        message: res.msg || "获取员工核算绩效金额和员工信息失败",
                        type: "warning",
                    });
                }
            }
        },
        async handleSave (flag) {
            const loadingRef = this.$loading({
                target: ".verifyDia .el-dialog"
            });

            try {
                await this.$refs.form.validate();

                const {checkMoney, remark} = this.PB;
                const res = await this.$axios({
                    url: "/api/empMerits/saveEmpMeritsCheckInfo",
                    method: "post",
                    data: {
                        id:this.clickrow.id,
                        remark, checkMoney,
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    this.cancelCheckAchievement()
                    if(flag=='next'){
                        this.showGrantDia = true
                        this.queryEmpMeritsSalaryInfo(2)
                    }

                    this.fetchData();
                    loadingRef && loadingRef.close();
                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        cancelCheckAchievement() {
            this.PB.checkMoney = ''
            this.PB.remark = ''
            this.$refs.form.clearValidate();
            setTimeout(() => {
                this.showVerifyDia = false
            }, 20);
        },
        async handleCheckUpdate(data) {
            let loadingRef;
            try {
                await this.$confirm("确定要重新加载当月考核员工吗？", "系统提示");
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/empMerits/refreshEmpMerits",
                    method: "post",
                    data: { },
                });
                if (res.code === 2000) {
                    // this.$message({
                    //     message: "重新加载成功",
                    //     type: "success",
                    // });
                    // loadingRef && loadingRef.close();
                    this.fetchData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "加载失败",
                        type: "warning",
                    });
                }
                loadingRef && loadingRef.close();
            }
        },

        openGrantDia(row){
             if(row.isCheck!=1){
                 this.$message({
                    message: "请先核算绩效工资",
                    type: "warning"
                });
                return
            }
            if(row.grantTimeName){
                 this.$message({
                    message: "已发放绩效工资",
                    type: "warning"
                });
                return
            }
            this.clickrow = row
            this.showGrantDia = true
            setTimeout(() => {
                this.$refs.grantform.clearValidate();
            }, 20);
            this.queryEmpMeritsSalaryInfo(2)
        },
        cancelGrantDia(){
            this.grant.grantTime = this.todayStr
            this.grant.grantMoney = ''
            this.grant.remark = ''
            this.$refs.grantform.clearValidate();
            setTimeout(() => {
                this.showGrantDia = false
            }, 20);


        },
        async handleGrantSave () {
            if(Number(this.grant.grantMoney) > Number(this.empInfo.checkMoney) ){
                this.$message({
                    message: "实发绩效工资最大不能大于核算绩效工资",
                    type: "warning"
                });
                return
            }
            const loadingRef = this.$loading({
                target: ".grantDia .el-dialog"
            });
            try {
                await this.$refs.grantform.validate();
                const {grantMoney, grantTime,remark} = this.grant;
                const res = await this.$axios({
                    url: "/api/empMerits/saveEmpMeritsGrantInfo",
                    method: "post",
                    data: {
                        id:this.clickrow.id,
                        remark, grantTime,
                        grantMoney
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    this.cancelGrantDia()
                    this.fetchData();
                    loadingRef && loadingRef.close();
                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
       
         // 详情
        handleDetail(data) {
            // this.$router.push({
            //     path: `/customer/employeeCerformanceList/Detail`,
            //     query: { id: data.id,type:1,searchTime:data.meritsTimeName },
            // });

            this.$router.push({
                name : `employeeCerformanceDetail`,
                params : { id: data.id,type:1,searchTime:data.meritsTimeName },
            });
        },

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        handleSubmit() {
            this.pageNum = 1;
            this.pageSize = 10;
            this.fetchData();
        },
        handleClear() {
            this.pageNum = 1;
            this.pageSize = 10;
            this.searchValueType=1
            this.searchValue=''
            this.searchRegion = '';
            this.searchTime = '';
            this.searchPost = '';
            this.searchIsCheck = '';
            this.fetchData();
        },
         async fetchQueryParam() {
            const res = await this.$axios({
                url: "/api/empMerits/queryParam",
                method: "post",
                data: {
                    startLevel:20, 
                    endLevel:''
                },
            });
            if (res.code === 2000) {
                this.regionList = res.data.regionList || [];
                this.postAllList = res.data.postAllList || [];
            } else {
                this.$message({
                    message: res.msg || "获取查询参数失败",
                    type: "warning",
                });
            }
        },
        async fetchData() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/empMerits/queryEmpMeritsList",
                method: "post",
                data: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    searchRegion:this.searchRegion,
                    searchTime:this.searchTime,
                    searchPost:this.searchPost,
                    searchIsCheck:this.searchIsCheck,
                    searchValueType:this.searchValueType,
                    searchValue:this.searchValue,
                    searchType:1, //1员工绩效列表 2经理绩效列表 
                },
            });
            loading.close();
            if (res.code === 2000) {
                // 大于20小于等于40 有权限也不显示
                res.data.empMeritsList.forEach(element => {
                    const _actionList = [...this.actionList];
                    element.actionList = _actionList;
                    // 没权限或者等级不满足
                    if(!this.auth.verify || (element.postLevel>20 && element.postLevel<=40 )  ){
                        const verifyActionIndex = element.actionList.findIndex(({name}) => name == "verify");
                        if (verifyActionIndex >= 0) element.actionList.splice(verifyActionIndex, 1)
                    }
                    if(!this.auth.grant || (element.postLevel>20 && element.postLevel<=40 )  ){
                        const grantActionIndex = element.actionList.findIndex(({name}) => name == "grant");
                        if (grantActionIndex >= 0) element.actionList.splice(grantActionIndex, 1)
                    }

                    if(!this.auth.detail){
                        const detailActionIndex = element.actionList.findIndex(({name}) => name == "detail");
                        if (detailActionIndex >= 0) element.actionList.splice(detailActionIndex, 1)
                    }
                });

                this.tableData = res.data.empMeritsList || [];
                this.total = res.data.count || 0;
            } else {
                this.$message({
                    message: res.msg || "获取数据失败",
                    type: "warning",
                });
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>


.header {
    height: 40px;
}
// 公用
.pl16 {
    padding-left: 16px;
}
.pr16 {
    padding-right: 16px;
}
.pt24 {
    padding-top: 24px;
}
.pb24 {
    padding-bottom: 24px;
}
.ml12 {
    margin-left: 12px;
}
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.flex1 {
    flex: 1;
}
.a-center {
    align-items: center;
}
.fb {
    font-weight: 600;
}
.f16 {
    font-size: 16px;
}
.hover {
    cursor: pointer;
}
.w_50{
    width: 50%;
}
.el-icon-devops {
    width: 12px;
    height: 12px;
    background: url('../../assets/images/export.png') center no-repeat;
    font-size: 12px;
    background-size: cover;
    margin-right: 5px;
}

::v-deep input:disabled::-webkit-input-placeholder{ //禁用的input框 不要placeholder
  -webkit-text-fill-color: rgba(255,255,255,0);
}
.cell-list-item .fine::before{
    content: "";
    width: 4px;
    height: 4px;
    margin-right: 6px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background-color: #6DD400;
}
.cell-list-item .fail::before{
    content: "";
    width: 4px;
    height: 4px;
    margin-right: 6px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background-color: #E02020;
}
// .t-center{  
//     text-align: center;
// }
// .t-right{
//     text-align: right;
// }
.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
    margin-bottom: 16px;
}
// 公用完
</style>